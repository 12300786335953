import React from "react";
import { Drawer, Empty, Table, Tag } from "antd";
import { formatProcessingTime, getLocalDateTimeString, getTagColor, getTheTimeDifference } from "../../utils";
import './drawer.scss';

interface DrawerComponentProps {
  activeDocData: {
    fileName: string;
    uuid: string;
    ackStatus: string;
  };
  drawer: boolean;
  handleDrawer: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  docAckData: any;
  isDocAckLoading: boolean;
  retryData: any[]
}

const getDocAckColumns = () => [
  {
    title: "Event",
    key: "Message",
    dataIndex: "Message",
    className: "event-column text-wrapper",
    render: (text: string) =>  (
      <div className="flex gp-10"> 
        <Tag bordered={false} color={getTagColor(text)} >
            {text}
        </Tag>       
      </div>
    ),
  },
  {
    title: "Start Time",
    key: "StartTime",
    dataIndex: "StartTime",
    className: "start-time-column text-wrapper",
    render: (text: string) => <div className="text-wrapper">{getLocalDateTimeString(text)}</div>,
  },
  {
    title: "End Time",
    key: "EndTime",
    dataIndex: "EndTime",
    className: "end-time-column text-wrapper",
    render: (text: string) => <div className="text-wrapper">{getLocalDateTimeString(text)}</div>,
  },
  {
    title: "Processing Time",
    key: "ProcessingTime",
    dataIndex: "ProcessingTime",
    className: "processing-time-column text-wrapper",
    render: (processingTime: { minutes?: number; seconds?: number; milliseconds?: number } | null) => {
        const formattedTime = formatProcessingTime(processingTime);
        return <div className="text-wrapper">{formattedTime}</div>;
    },
  },
];

const getDocRetryColumns = () => [
  {
    title: "Start Time",
    key: "createdAt",
    dataIndex: "createdAt",
    className: "start-time-column text-wrapper",
    render: (text: string) => <div className="text-wrapper">{getLocalDateTimeString(text)}</div>,
  },
  {
    title: "End Time",
    key: "createdAt",
    dataIndex: "createdAt",
    className: "end-time-column text-wrapper",
    render: (text: string) => <div className="text-wrapper">{getLocalDateTimeString(text)}</div>,
  },
  {
    title: "Processing Time",
    key: "ProcessingTime",
    dataIndex: "ProcessingTime",
    className: "processing-time-column text-wrapper",
    render: (_: string, record: any) => {
        const formattedTime = getTheTimeDifference(record.createdAt, record.updatedAt);
        return <div className="text-wrapper">{formattedTime}</div>;
    },
  },
];

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  activeDocData,
  drawer,
  handleDrawer,
  containerRef,
  docAckData,
  isDocAckLoading,
  retryData
}) => {
  
  return (
    <Drawer
      title={activeDocData.fileName}
      onClose={() => handleDrawer()}
      open={drawer}
      getContainer={() => containerRef.current ?? document.body}
      placement="right"
      mask={false}
      width="50%"
      className="drawer-wrapper"
      rootStyle={{ top: "170px", bottom: "29px" }}
    >
      <div className="drawer-content">
        <div className="flex flex-column gp-10">
          <h3 className="text-wrapper"><strong>Document UUID:</strong> <span style={{ fontSize: '13px' }}>{activeDocData.uuid}</span></h3>
          <h3 className="text-wrapper"><strong>Acknowledgement:</strong> 
            <Tag bordered={false} color={getTagColor(activeDocData.ackStatus)} style={{ fontSize: '13px' }}>
              {activeDocData.ackStatus}
            </Tag>
          </h3>
        </div>
        <div>
          {/* <div className="gp-10 table-section text-wrapper">
            <h3>Acknowledgement</h3>
              <Table
                columns={getDocAckColumns()}
                pagination={false}
                dataSource={docAckData?.ACK_EVENT || []} 
                loading={isDocAckLoading}
                className="compact-table"
                locale={{ emptyText: <Empty>No Events Found</Empty> }}
              />
          </div> */}
          <div className="table-section">
            <h3>Pipeline Time Events</h3>
              <Table
                columns={getDocAckColumns()}
                pagination={false}
                dataSource={docAckData?.PIPELINE_TIME_EVENT || []} 
                loading={isDocAckLoading}
                className="compact-table"
                locale={{ emptyText: <Empty>No Events Found</Empty> }}
              />
          </div>
          <div className="table-section">
            <h3>Retry Attempts</h3>
              <Table
                columns={getDocRetryColumns()}
                pagination={false}
                dataSource={retryData || []} 
                loading={isDocAckLoading}
                className="compact-table"
                locale={{ emptyText: <Empty>No Attempts Found</Empty> }}
              />
          </div>

        </div>
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
