import { useEffect, useState } from "react";
import { PdfViewer } from "../../components";
import "./document-container.scss";
import { Badge, Collapse, Empty, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { documentService } from "../../api";
import { SECTION, openNotificationWithIcon } from "../../utils";
import { Tooltip } from "antd/lib";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDocumentReview } from "../../pages/Document";
import { SplitSummary } from "./components/split-summary/SplitSummary";
import { Insights } from "./components/insights/Insights";
import { AskAuto } from "./components/chat-container/AskAuto";
import {
  PaginationLeftArrowIcon,
  PaginationRightArrowIcon,
} from "../../assets/icons";
import { SplitDataItem, SplitDataType } from "./constants";
import { useParams } from "react-router-dom";
import { DocumentData } from "../../interfaces/document";
import { EvidenceContentJSON } from "../../components/pdf-viewer/JSONViewer";

const HEADINGS = {
  DOCUMENT_INSIGHTS: "DOCUMENT INSIGHTS",
  DOCUMENT_SPLIT_SUMMARY: "DOCUMENT SPLIT SUMMARY",

};

export const DocumentContainer = () => {
  const [pdfViewer, setPdfViewer] = useState({ signedUrl: "", currentPage: 1 });
  const [activeTab, setActiveTab] = useState("1");
  const [activeSection, setActiveSection] = useState("default");
  const [selectedCategory, setSelectedCategory] = useState({} as SplitDataItem);
  const [splitData, setSplitData] = useState([] as SplitDataType[]);
  const [ mappingData, setMappingData ] = useState([])
  const { documentUuid } = useParams();
  const { documentReview } = useDocumentReview();

  useEffect(() => {
    const fetchSingedUrlByPath = async () => {
      try {
        const { signedUrl } = await documentService.getSignedUrlByFilepath(
          documentReview.fileName
        );
        handlePdfChange("signedUrl", signedUrl);
      } catch (error: any) {
        console.log(error);
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    };
    fetchSingedUrlByPath();
  }, []);

  const getSections = async () => {
    try {
      const { data } = await documentService.getSectionsAndInsights(
        documentUuid
      );
      setSplitData(data);
      const firstStartPageOneItem = data.find((item) => item.StartPage === 1);
      if (firstStartPageOneItem) {
        setSelectedCategory({
          key: 0,
          section: firstStartPageOneItem.Section,
          category: firstStartPageOneItem.Category,
          pageRange: `${
            firstStartPageOneItem.StartPage - firstStartPageOneItem.EndPage
          }`,
          feedbackStatus: firstStartPageOneItem.feedbackStatus,
          insights: firstStartPageOneItem.insights,
          sectionId: firstStartPageOneItem.sectionId,
          confidenceScore: firstStartPageOneItem.confidenceScore,
          noOfCommentsInsight: firstStartPageOneItem.noOfCommentsInsight,
          noOfCommentsSection: firstStartPageOneItem.noOfCommentsSection,
        });
      }
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    }
  };

  const getPlaceholderMappingData = async ( ) => {
    const { data } = await documentService.getPlaceholderMapping(
      documentUuid
    );
    setMappingData(data);
  }

  useEffect(() => {
    getSections();
    getPlaceholderMappingData();
  }, []);

  const handlePdfChange = (key: string, value: string | number) =>
    setPdfViewer((prev) => ({ ...prev, [key]: value }));

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
  };

  const handleRightClick = () => {
    if (activeSection === SECTION.PDF || activeSection === SECTION.DEFAULT) {
      setActiveSection(SECTION.PDF);
    } else {
      setActiveSection(SECTION.DEFAULT);
    }
  };

  const handleLeftClick = () => {
    if (activeSection === SECTION.TAB || activeSection === SECTION.DEFAULT) {
      setActiveSection(SECTION.TAB);
    } else {
      setActiveSection(SECTION.DEFAULT);
    }
  };

  useEffect(() => {
    if (selectedCategory.pageRange) {
      setPdfViewer((prev) => ({
        ...prev,
        currentPage:
          parseInt(selectedCategory.pageRange?.split("-")[0]?.trim(), 10) || 1,
      }));
    }
  }, [selectedCategory.pageRange]);
  // console.log(selectedCategory);

  const renderEvidenceContentJSON = () => {
    if(!documentReview.tolstoyJson){
      return (
        <div className="flex flex-column gp jcc aic">

          <Empty description={"No Data Found"} />
          <p>No Data Found</p>
        </div>
    )
    }else{
      return  (
        <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={handleTabChange}
        tabBarGutter={25}
        size="large"
        className={`point-tabs-container ${
          activeSection === SECTION.TAB ? "full-tab" : ""
        }`}
      >
        <TabPane tab={<span>Final JSON</span>} key={"1"}>
          <EvidenceContentJSON
            pageInfo={documentReview.tolstoyJson}
        />
        </TabPane>
        <TabPane tab={<span>Placeholder Mapping</span>} key={"2"}>
          <EvidenceContentJSON
            pageInfo={mappingData}
        />
        </TabPane>
    </Tabs>)
    }
  };

  return (
    <div
      className={`document-page-container ${
        activeSection === SECTION.TAB ? "full-tab" : ""
      }`}
    >
      {(activeSection === "pdf" || activeSection === "default") && (
        renderEvidenceContentJSON()
      )}
      <div
        className={`drawer ${
          activeSection === SECTION.TAB
            ? "tab-drawer"
            : activeSection === SECTION.PDF
            ? "pdf-drawer"
            : ""
        }`}
      >
        <div className="arrow-icons">
          {(activeSection === SECTION.TAB ||
            activeSection === SECTION.DEFAULT) && (
            <RightOutlined className="right-arrow" onClick={handleRightClick} />
          )}
          {(activeSection === SECTION.PDF ||
            activeSection === SECTION.DEFAULT) && (
            <LeftOutlined className="left-arrow" onClick={handleLeftClick} />
          )}
        </div>
      </div>
      {(activeSection === SECTION.TAB || activeSection === SECTION.DEFAULT) && (

            <div className="collapse gp flex flex-column">
              <Collapse
                accordion
                expandIconPosition="end"
                expandIcon={(e) =>
                  e.isActive ? (
                    <PaginationLeftArrowIcon />
                  ) : (
                    <PaginationRightArrowIcon />
                  )
                }
                defaultActiveKey={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
              >
                <Collapse.Panel
                  header={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
                  key={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
                >
                  <SplitSummary
                    splitData={splitData}
                    setSelectedCategory={setSelectedCategory}
                    pdfUrl={pdfViewer.signedUrl}
                    getSections={getSections}
                  />

                </Collapse.Panel>
              </Collapse>
              
              {/* <Collapse
                accordion
                expandIconPosition="end"
                expandIcon={(e) =>
                  e.isActive ? (
                    <PaginationLeftArrowIcon />
                  ) : (
                    <PaginationRightArrowIcon />
                  )
                }
                defaultActiveKey={HEADINGS.DOCUMENT_INSIGHTS}
              >
                <Collapse.Panel
                  header={HEADINGS.DOCUMENT_INSIGHTS}
                  key={HEADINGS.DOCUMENT_INSIGHTS}
                >
                  <Insights
                    selectedCategory={selectedCategory}
                    sectionId={selectedCategory.sectionId}
                    getSections={getSections}
                  />
                </Collapse.Panel>
              </Collapse> */}
            </div>
         
      )}
    </div>
  );
};

interface Props {
  document: DocumentData;
}
