export const getCurrentDateTime = () =>
  new Date().toLocaleDateString() + ", " + new Date().toLocaleTimeString();

export const getDate = (date: Date) => new Date(date).toISOString().split("T")[0];

export const getTime = (date: Date) =>
  `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
      .split(",")[1]
  }`;

  export const getTimeInSeconds = (date: Date) => `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      })
      .split(",")[1]
  }`

export const getLocalDateTimeStringFromISO = (datetime: string, dateFirst: boolean = true) => {
  if (!datetime) return "-";
  const date = new Date(datetime);
  return dateFirst ? `${getDate(date)} ${getTime(date)}` : `${getTime(date)} ${getDate(date)}`;
};

export const getLocalDateTimeString = (datetime: string) => {
  if (!datetime) return "-";
  const date = new Date(datetime);
  return `${getDate(date)} ${getTimeInSeconds(date)}`;
}

export const getTheTimeDifference = (createAt: string, updatedAt: string): string => {
  try {
    // Parse the input date strings into Date objects
    const createdDate = new Date(createAt);
    const updatedDate = new Date(updatedAt);
    
    // Check for invalid date inputs
    if (isNaN(createdDate.getTime()) || isNaN(updatedDate.getTime())) {
      throw new Error('Invalid date format. Please provide valid date strings in ISO format or a parsable date format.');
    }
    
    // Calculate the difference in milliseconds
    const timeDifferenceMs = updatedDate.getTime() - createdDate.getTime();
    
    // Check if the difference is negative, which means incorrect order of dates
    if (timeDifferenceMs < 0) {
      throw new Error('The updatedAt date must be later than the createAt date.');
    }
    
    // Convert milliseconds to days, hours, minutes, and seconds
    const millisecondsInSecond = 1000;
    const millisecondsInMinute = millisecondsInSecond * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;
    
    const days = Math.floor(timeDifferenceMs / millisecondsInDay);
    const hours = Math.floor((timeDifferenceMs % millisecondsInDay) / millisecondsInHour);
    const minutes = Math.floor((timeDifferenceMs % millisecondsInHour) / millisecondsInMinute);
    const seconds = Math.floor((timeDifferenceMs % millisecondsInMinute) / millisecondsInSecond);
    
    // Format the result as a human-readable string
    const parts = [];
    if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
    
    return parts.join(', ');
  } catch (error :any) {
    return `Error: ${error.message}`;
  }
};


export const formatProcessingTime = (processingTime: { minutes?: number; seconds?: number; milliseconds?: number } | null): string => {
  if (!processingTime) return '0 seconds'; 

  const { minutes = 0, seconds = 0, milliseconds = 0 } = processingTime;

  const totalSeconds = seconds + milliseconds / 1000;
  
  const totalMinutes = minutes + Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  let formattedTime = '';

  if (totalMinutes > 0) {
    formattedTime += `${totalMinutes} min${totalMinutes !== 1 ? 's' : ''} `;
  }

  if (remainingSeconds > 0 || formattedTime === '') {
    formattedTime += `${remainingSeconds.toFixed(2)} second${remainingSeconds !== 1 ? 's' : ''}`;
  }

  return formattedTime.trim();
};


export function convertToLocalTime(utcDate: string, locale: string = 'en-IN'): string {
  if (!utcDate) {
    throw new Error('Invalid date format');
  }
  const localDate = new Date(utcDate).toLocaleString(locale, { 
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
    hour12: true 
  });

  return localDate
    .replace(/(am|pm)/i, (match) => match.toUpperCase()) 
    .replace(/\//g, '-'); 
}
